<template>
    <el-card class="box-card">
        <div @click="goto()">
            <el-row>
                <el-col :span="14">
                    <el-row>
                        <el-col style="font-size: 18px;height:50px;color: #2d2d2d; font-weight: bold;">{{project.name}}
                        </el-col>
                        <el-col style="height: 30px">未完成采购单：<span style="color:#409EFF">{{group.no||0}}</span></el-col>
                        <el-col style="height: 30px">已完成采购单：<span style="color:#409EFF">{{group.yes||0}}</span></el-col>
                    </el-row>
                </el-col>
                <el-col :span="10">
                    <el-progress type="circle" :percentage="group.rate"></el-progress>
                </el-col>
            </el-row>
        </div>
    </el-card>
</template>

<script>
export default {
    props: {
        project: { default: {} },
        counts: { default: [] }
    },
    data() {
        return {
            group: { no: 0, yes: 0, rate: 0 }
        }
    },
    methods: {
        goto() {
            console.log(555555555555555);
            this.sa_admin.navigateTo("purchase-list", this.project);
        }
    },

    created() {
        let group = this.counts.filter(item => {
            return (item.projectId == this.project.id)
        });


        if (group.length > 0) {
            this.group = group[0];
            let all = this.group.yes + this.group.no;
            if (all != 0) {
                this.group.rate = parseInt(this.group.yes / all * 100);
            }
        }

    }
}
</script>

<style >
.box-card {
    margin: 10px;
}
</style>
